<template>
  <div>
    <ul class="nav nav-tabs" role="tablist">
      <li role="presentation" class="active">
        <a href="#pickl" aria-controls="pickl" role="tab" data-toggle="tab">
          Pickl Insight Stats
        </a>
      </li>
      <li role="presentation" v-if="basicSurveyDetails.campaign_details.type =='NON_GEO'">
        <a href="#media" aria-controls="pickl" role="tab" data-toggle="tab">
          Media Insight Stats
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div role="tabpanel" class="tab-pane fade in active"  id="pickl" style="margin-top: 20px;">
        <div class="wizard-container row" style="width:90%;">
          <button type="button" class="pull-right btn-positive" @click="exportInsightstats" v-if="this.$store.state.userData.role == 'super-admin' || this.$store.state.userData.role == 'brand'">Export Insights Report</button>
          <h2 ><span style = "text-transform:capitalize;"><strong>Campaign Name: </strong>{{basicSurveyDetails.campaign_details.name}}</span></h2>

          <h2 ><span style = "text-transform:capitalize;"><strong>Insight Name: </strong>{{basicSurveyDetails.title}}</span></h2>
          <div class="col-sm-6">
            <div class="green-border-box">
              <h3 style="text-align: center;"><span ><strong> {{basicSurveyDetails.totalCompletedTasks}}: Total overall tasks completed with insights </strong></span></h3>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="green-border-box">
              <h3 style="text-align: center;"><span ><strong> {{basicSurveyDetails.totalSurveyResponse}}: Total Insights (Responses)</strong></span></h3>
            </div>
          </div>
          <div class="col-sm-8 " style="margin-top:10px;" v-if="basicSurveyDetails.campaign_details.type =='GEO'">
            <div class="green-border-box">
              <h4 style="margin-left:15px;">Consumer Insights</h4><hr/>
              <div class="col-sm-6" >
                <h4 style="color:#782B55">{{ keys[0] }}</h4>
                <vue-apex-charts  v-if="!loading"  :options="firstQuestionchartOptions" :series="firstQuestion"></vue-apex-charts>
              </div>
              <div class="col-sm-6">
                <h4 style="color:#782B55">{{ keys[1] }}</h4>
                <vue-apex-charts  v-if="!loading"  :options="secondQuestionchartOptions" :series="secondQuestion"></vue-apex-charts>
              </div>
            </div>
          </div>
          <div class="col-sm-4 " style="margin-top:10px;" v-if="basicSurveyDetails.campaign_details.type =='GEO'">
            <div class="green-border-box">
              <h4 style="text-align: center;">User Selfies</h4>
              <img :src="image" class="img-responsive" style="width: 100%;"/>
              <span style="font-size: 13px;font-weight: bold;text-align: center;display: block;">{{ storeAddress }}</span>
            </div>
          </div>
          <div v-for="(question) in basicSurveyDetails.surveyQuestions" :key="question.id">
            <div class="col-sm-6 padd-10" v-if="question.question_type != 'TEXT'">
              <div class="stats-cls">
                <h3 style = "text-transform:capitalize;"><strong>Q: </strong>{{question.question}}</h3>
                <!-- <h3 style = "text-transform:capitalize;"><strong>Total Responses: </strong>{{question.totalResponse - 1}}</h3> -->
                <vue-apex-charts  :options="createChartOption(question)" :series="createSeries(question)"></vue-apex-charts>
              </div>
            </div>
          </div>
        </div>
        <div class="wizard-container row" style="width:90%;">
          <h2 ><span style = "text-transform:capitalize;"><strong>Textual Question Answers </strong></span></h2>
          <hr/>
          <div v-for="(question) in basicSurveyDetails.surveyQuestions" :key="question.id">
            <div class="col-sm-12 padd-10" v-if="question.question_type == 'TEXT'">
              <div class="stats-cls">
                <h3 style="text-transform:capitalize;min-height:53px;"><strong>Q: </strong>{{question.question}}</h3>
                <!-- <h3 style = "text-transform:capitalize;"><strong>Total Responses: </strong>{{question.totalResponse - 1}}</h3> -->
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Answer</th>
                      <th scope="col">Response Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(userAnswer, index) in question.surveyAnswers" :key="index">
                      <th scope="row">{{index + 1}}</th>
                      <td>{{userAnswer.text_answer}}</td>
                      <td>{{userAnswer.totalResponse}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="basicSurveyDetails.campaign_details.type =='NON_GEO'" role="tabpanel" class="tab-pane fade in"  id="media" style="margin-top:20px;">
        <div class="wizard-container row" style="width:90%;">
          <button type="button" class="pull-right btn-positive" @click="exportInsightstats" v-if="this.$store.state.userData.role == 'super-admin' || this.$store.state.userData.role == 'brand'">Export Insights Report</button>
          <h2 ><span style = "text-transform:capitalize;"><strong>Campaign Name: </strong>{{mediaSurveyDetails.campaign_details.name}}</span></h2>

          <h2 ><span style = "text-transform:capitalize;"><strong>Insight Name: </strong>{{mediaSurveyDetails.title}}</span></h2>
          <div class="col-sm-6">
            <div class="green-border-box">
              <h3 style="text-align: center;"><span ><strong> {{mediaSurveyDetails.totalCompletedTasks}}: Total overall tasks completed with insights </strong></span></h3>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="green-border-box">
              <h3 style="text-align: center;"><span ><strong> {{mediaSurveyDetails.totalSurveyResponse}}: Total Insights (Responses)</strong></span></h3>
            </div>
          </div>
          <div v-for="(question) in mediaSurveyDetails.surveyQuestions" :key="question.id">
            <div class="col-sm-6 padd-10" v-if="question.question_type != 'TEXT'">
              <div class="stats-cls">
                <h3 style = "text-transform:capitalize;min-height:53px;"><strong>Q: </strong>{{question.question}}</h3>
                <!-- <h3 style = "text-transform:capitalize;"><strong>Total Responses: </strong>{{question.totalResponse - 1}}</h3> -->
                <vue-apex-charts  :options="createChartOption(question)" :series="createSeries(question)"></vue-apex-charts>
              </div>
            </div>
          </div>
        </div>
        <div class="wizard-container row" style="width:90%;margin-top:20px;">
          <h2 ><span style = "text-transform:capitalize;"><strong>Textual Question Answers </strong></span></h2>
          <hr/>
          <div v-for="(question) in mediaSurveyDetails.surveyQuestions" :key="question.id">
            <div class="col-sm-12 padd-10" v-if="question.question_type == 'TEXT'">
              <div class="stats-cls">
                <h3 style = "text-transform:capitalize;"><strong>Q: </strong>{{question.question}}</h3>
                <!-- <h3 style = "text-transform:capitalize;"><strong>Total Responses: </strong>{{question.totalResponse - 1}}</h3> -->
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Answer</th>
                      <th scope="col">Response Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(userAnswer, index) in question.surveyAnswers" :key="index">
                      <th scope="row">{{index + 1}}</th>
                      <td>{{userAnswer.text_answer}}</td>
                      <td>{{userAnswer.totalResponse}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts
  },
  data: function () {
    return {
      localTime: '',
      selfies: [],
      image: '',
      userName: '',
      storeName: '',
      storeAddress: '',
      firstQuestion: [],
      secondQuestion: [],
      ShoppingInshgits: [],
      keys: [],
      loading: true,
      firstQuestionchartOptions: {
        chart: {
          title: {
            text: 'Products Insights'
          },
          type: 'pie'
        },
        labels: []

      },
      secondQuestionchartOptions: {
        chart: {
          title: {
            text: 'Products Insights'
          },
          type: 'pie'
        },
        labels: []

      },
      series: [],
      picklCampaignId: this.$route.params.campaignId,
      basicSurveyDetails: { campaign_details: '' },
      mediaSurveyDetails: { campaign_details: '' },

      brandId: 0,
      totalResponsesAnswers: 0
    }
  },
  methods: {
    showLocaleTime: function () {
      var here = this
      let counter = 1
      setInterval(function () {
        here.image = here.selfies[counter]['image'] + '?width=300'
        here.storeAddress = here.selfies[counter]['address']
        here.userName = here.selfies[counter]['name']
        here.storeName = here.selfies[counter]['store']
        counter++
        if (counter === (here.selfies.length - 1)) {
          counter = 0
        }
      }, 5000)
    },
    exportInsightstats () {
      let userType = 'brand'
      if (this.$store.state.userData.role === 'super-admin') {
        userType = 'sadmin'
      }
      if (this.$store.state.userData.role === 'staff') {
        userType = 'sadmin'
      }
      this.$store.dispatch('exportInsightStats', { surveyId: this.$route.params.surveyId, userType: userType }).then((response) => {
        this.$notify({ type: 'success', text: 'Insight stats has been send via email to you!' })
      }, (response) => {
        this.busy = false
      })
    },

    getTotalResponses () {
      this.basicSurveyDetails.surveyQuestions.forEach((i, j) => {
        // console.log(i, j)
        this.totalResponsesAnswers = this.totalResponsesAnswers + i.totalResponse
      })
    },

    createChartOption (question) {
      let chartOption = {
        chart: { type: 'pie' },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: '75%',
              height: '300px'
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
      let labels = []
      question.surveyAnswers.forEach(element => {
        labels.push(element.answer_option)
      })
      chartOption.labels = labels
      return chartOption
    },
    createSeries (question) {
      let series = []
      question.surveyAnswers.forEach(element => {
        series.push(element.totalResponse)
      })
      return series
    },
    getPicklCampaignInsightsById () {
      this.loading = true
      this.$http
        .get(process.env.VUE_APP_API_BASE_URL + `/brands/${this.brandId}/campaign-insights/${this.picklCampaignId}?includeAnswers=true&includeQuestions=true&includeSurveyStats=true`)
        .then(
          (response) => {
            this.basicSurveyDetails = response.body.pickl
            this.mediaSurveyDetails = response.body.media
            this.getTotalResponses()
            this.keys = Object.keys(response.body.pickl.shopping_insights)
            if (typeof response.body.pickl.shopping_insights[this.keys[0]] !== 'undefined') {
              let firstQuestion = response.body.pickl.shopping_insights[this.keys[0]]
              firstQuestion.forEach((q) => {
                this.firstQuestionchartOptions.labels.push(q.x)
                this.firstQuestion.push(q.y)
              })
            }
            if (typeof response.body.pickl.shopping_insights[this.keys[1]] !== 'undefined') {
              let secondQuestion = response.body.pickl.shopping_insights[this.keys[1]]
              secondQuestion.forEach((q) => {
                this.secondQuestionchartOptions.labels.push(q.x)
                this.secondQuestion.push(q.y)
              })
            }

            this.selfies = response.body.pickl.selfies

            if (this.selfies.length > 0) {
              this.image = this.selfies[0]['image'] + '?width=300'
              this.storeAddress = this.selfies[0]['address']
              this.userName = this.selfies[0]['name']
              this.storeName = this.selfies[0]['store']
              this.showLocaleTime()
            }

            this.loading = false
            console.log(this.firstQuestion)
          },
          (response) => {
            console.log(response.body)
          }
        )
    }
  },
  mounted () {
    this.getPicklCampaignInsightsById()
  },
  created () {
    if (this.$store.state.userData.role === 'super-admin' || this.$store.state.userData.role === 'staff') {
      this.brandId = this.$route.params.brandId
    } else {
      this.brandId = this.$store.state.userData.brandId
    }
  }
}
</script>
<style scoped>
.wizard-container {
  width:70%;
  margin:auto;
  border: solid 2px rgb(130, 222, 109);
  padding:1%;
  border-radius:20px;
}
select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url("/img/Create Pickl/selectproductDropdown_icon.png");
  background-color: white;
}
.answer-input {
  height: 30px;
  border-radius: 10px;
  margin:0;
  width:90%;
}
.add-more-ans {
  font-size: 15px;
  text-decoration: none;
  display: block;
  margin-left: 3%;
}
.single-quesion-box {
  border: solid 1px rgb(130, 222, 109);
  padding:1%;
  border-radius:20px;
  margin: 10px;

}
.add-question-button {

  border: solid 1px rgb(130, 222, 109);
  color: black;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  display: block;
  width: 20%;
  text-align: center;
  padding: 1%;
  border-radius: 10px;
}
a {
  color:#782b54;
}
.delete-question-link {
  font-size:16px;
  margin-top:40px;
}
.answer-container {
  margin-left: 2%;
  margin:1%;
}
.remove-answer-button {
  background: #782b54;
  height: 35px;
  border: none;
  border-radius: 150px;
  color: white;
  padding: 3px 10px 0px 10px;
}
.localstorage-existing-data-notice {
    text-align: center;
    display: block;
    color: black;
    font-size: 16px;
    margin-bottom: 10px;
    transition: opacity cubic-bezier(0, 0.52, 1, 1) 1s
  }
  .progress-bar-container {
    width: 60%;
    margin: auto;
  }
@media only screen and (max-width: 1024px) {
  .wizard-container {
    width:100%;
    margin:auto;
    border: solid 2px rgb(130, 222, 109);
    padding:1%;
    border-radius:20px;
  }

}
.padd-10 {
  padding:10px;
}
.stats-cls {
  border: 1px solid #11db6d;
  border-radius: 10px;
  padding: 1%;
}
</style>
