var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("ul", { staticClass: "nav nav-tabs", attrs: { role: "tablist" } }, [
      _vm._m(0),
      _vm.basicSurveyDetails.campaign_details.type == "NON_GEO"
        ? _c("li", { attrs: { role: "presentation" } }, [
            _c(
              "a",
              {
                attrs: {
                  href: "#media",
                  "aria-controls": "pickl",
                  role: "tab",
                  "data-toggle": "tab"
                }
              },
              [_vm._v("\n        Media Insight Stats\n      ")]
            )
          ])
        : _vm._e()
    ]),
    _c("div", { staticClass: "tab-content" }, [
      _c(
        "div",
        {
          staticClass: "tab-pane fade in active",
          staticStyle: { "margin-top": "20px" },
          attrs: { role: "tabpanel", id: "pickl" }
        },
        [
          _c(
            "div",
            {
              staticClass: "wizard-container row",
              staticStyle: { width: "90%" }
            },
            [
              this.$store.state.userData.role == "super-admin" ||
              this.$store.state.userData.role == "brand"
                ? _c(
                    "button",
                    {
                      staticClass: "pull-right btn-positive",
                      attrs: { type: "button" },
                      on: { click: _vm.exportInsightstats }
                    },
                    [_vm._v("Export Insights Report")]
                  )
                : _vm._e(),
              _c("h2", [
                _c(
                  "span",
                  { staticStyle: { "text-transform": "capitalize" } },
                  [
                    _c("strong", [_vm._v("Campaign Name: ")]),
                    _vm._v(_vm._s(_vm.basicSurveyDetails.campaign_details.name))
                  ]
                )
              ]),
              _c("h2", [
                _c(
                  "span",
                  { staticStyle: { "text-transform": "capitalize" } },
                  [
                    _c("strong", [_vm._v("Insight Name: ")]),
                    _vm._v(_vm._s(_vm.basicSurveyDetails.title))
                  ]
                )
              ]),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("div", { staticClass: "green-border-box" }, [
                  _c("h3", { staticStyle: { "text-align": "center" } }, [
                    _c("span", [
                      _c("strong", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.basicSurveyDetails.totalCompletedTasks) +
                            ": Total overall tasks completed with insights "
                        )
                      ])
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("div", { staticClass: "green-border-box" }, [
                  _c("h3", { staticStyle: { "text-align": "center" } }, [
                    _c("span", [
                      _c("strong", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.basicSurveyDetails.totalSurveyResponse) +
                            ": Total Insights (Responses)"
                        )
                      ])
                    ])
                  ])
                ])
              ]),
              _vm.basicSurveyDetails.campaign_details.type == "GEO"
                ? _c(
                    "div",
                    {
                      staticClass: "col-sm-8 ",
                      staticStyle: { "margin-top": "10px" }
                    },
                    [
                      _c("div", { staticClass: "green-border-box" }, [
                        _c("h4", { staticStyle: { "margin-left": "15px" } }, [
                          _vm._v("Consumer Insights")
                        ]),
                        _c("hr"),
                        _c(
                          "div",
                          { staticClass: "col-sm-6" },
                          [
                            _c("h4", { staticStyle: { color: "#782B55" } }, [
                              _vm._v(_vm._s(_vm.keys[0]))
                            ]),
                            !_vm.loading
                              ? _c("vue-apex-charts", {
                                  attrs: {
                                    options: _vm.firstQuestionchartOptions,
                                    series: _vm.firstQuestion
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-sm-6" },
                          [
                            _c("h4", { staticStyle: { color: "#782B55" } }, [
                              _vm._v(_vm._s(_vm.keys[1]))
                            ]),
                            !_vm.loading
                              ? _c("vue-apex-charts", {
                                  attrs: {
                                    options: _vm.secondQuestionchartOptions,
                                    series: _vm.secondQuestion
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.basicSurveyDetails.campaign_details.type == "GEO"
                ? _c(
                    "div",
                    {
                      staticClass: "col-sm-4 ",
                      staticStyle: { "margin-top": "10px" }
                    },
                    [
                      _c("div", { staticClass: "green-border-box" }, [
                        _c("h4", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("User Selfies")
                        ]),
                        _c("img", {
                          staticClass: "img-responsive",
                          staticStyle: { width: "100%" },
                          attrs: { src: _vm.image }
                        }),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "13px",
                              "font-weight": "bold",
                              "text-align": "center",
                              display: "block"
                            }
                          },
                          [_vm._v(_vm._s(_vm.storeAddress))]
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._l(_vm.basicSurveyDetails.surveyQuestions, function(
                question
              ) {
                return _c("div", { key: question.id }, [
                  question.question_type != "TEXT"
                    ? _c("div", { staticClass: "col-sm-6 padd-10" }, [
                        _c(
                          "div",
                          { staticClass: "stats-cls" },
                          [
                            _c(
                              "h3",
                              {
                                staticStyle: { "text-transform": "capitalize" }
                              },
                              [
                                _c("strong", [_vm._v("Q: ")]),
                                _vm._v(_vm._s(question.question))
                              ]
                            ),
                            _c("vue-apex-charts", {
                              attrs: {
                                options: _vm.createChartOption(question),
                                series: _vm.createSeries(question)
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ])
              })
            ],
            2
          ),
          _c(
            "div",
            {
              staticClass: "wizard-container row",
              staticStyle: { width: "90%" }
            },
            [
              _vm._m(1),
              _c("hr"),
              _vm._l(_vm.basicSurveyDetails.surveyQuestions, function(
                question
              ) {
                return _c("div", { key: question.id }, [
                  question.question_type == "TEXT"
                    ? _c("div", { staticClass: "col-sm-12 padd-10" }, [
                        _c("div", { staticClass: "stats-cls" }, [
                          _c(
                            "h3",
                            {
                              staticStyle: {
                                "text-transform": "capitalize",
                                "min-height": "53px"
                              }
                            },
                            [
                              _c("strong", [_vm._v("Q: ")]),
                              _vm._v(_vm._s(question.question))
                            ]
                          ),
                          _c("table", { staticClass: "table" }, [
                            _vm._m(2, true),
                            _c(
                              "tbody",
                              _vm._l(question.surveyAnswers, function(
                                userAnswer,
                                index
                              ) {
                                return _c("tr", { key: index }, [
                                  _c("th", { attrs: { scope: "row" } }, [
                                    _vm._v(_vm._s(index + 1))
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(userAnswer.text_answer))
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(userAnswer.totalResponse))
                                  ])
                                ])
                              }),
                              0
                            )
                          ])
                        ])
                      ])
                    : _vm._e()
                ])
              })
            ],
            2
          )
        ]
      ),
      _vm.basicSurveyDetails.campaign_details.type == "NON_GEO"
        ? _c(
            "div",
            {
              staticClass: "tab-pane fade in",
              staticStyle: { "margin-top": "20px" },
              attrs: { role: "tabpanel", id: "media" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "wizard-container row",
                  staticStyle: { width: "90%" }
                },
                [
                  this.$store.state.userData.role == "super-admin" ||
                  this.$store.state.userData.role == "brand"
                    ? _c(
                        "button",
                        {
                          staticClass: "pull-right btn-positive",
                          attrs: { type: "button" },
                          on: { click: _vm.exportInsightstats }
                        },
                        [_vm._v("Export Insights Report")]
                      )
                    : _vm._e(),
                  _c("h2", [
                    _c(
                      "span",
                      { staticStyle: { "text-transform": "capitalize" } },
                      [
                        _c("strong", [_vm._v("Campaign Name: ")]),
                        _vm._v(
                          _vm._s(_vm.mediaSurveyDetails.campaign_details.name)
                        )
                      ]
                    )
                  ]),
                  _c("h2", [
                    _c(
                      "span",
                      { staticStyle: { "text-transform": "capitalize" } },
                      [
                        _c("strong", [_vm._v("Insight Name: ")]),
                        _vm._v(_vm._s(_vm.mediaSurveyDetails.title))
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "green-border-box" }, [
                      _c("h3", { staticStyle: { "text-align": "center" } }, [
                        _c("span", [
                          _c("strong", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.mediaSurveyDetails.totalCompletedTasks
                                ) +
                                ": Total overall tasks completed with insights "
                            )
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "green-border-box" }, [
                      _c("h3", { staticStyle: { "text-align": "center" } }, [
                        _c("span", [
                          _c("strong", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.mediaSurveyDetails.totalSurveyResponse
                                ) +
                                ": Total Insights (Responses)"
                            )
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _vm._l(_vm.mediaSurveyDetails.surveyQuestions, function(
                    question
                  ) {
                    return _c("div", { key: question.id }, [
                      question.question_type != "TEXT"
                        ? _c("div", { staticClass: "col-sm-6 padd-10" }, [
                            _c(
                              "div",
                              { staticClass: "stats-cls" },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticStyle: {
                                      "text-transform": "capitalize",
                                      "min-height": "53px"
                                    }
                                  },
                                  [
                                    _c("strong", [_vm._v("Q: ")]),
                                    _vm._v(_vm._s(question.question))
                                  ]
                                ),
                                _c("vue-apex-charts", {
                                  attrs: {
                                    options: _vm.createChartOption(question),
                                    series: _vm.createSeries(question)
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ])
                  })
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "wizard-container row",
                  staticStyle: { width: "90%", "margin-top": "20px" }
                },
                [
                  _vm._m(3),
                  _c("hr"),
                  _vm._l(_vm.mediaSurveyDetails.surveyQuestions, function(
                    question
                  ) {
                    return _c("div", { key: question.id }, [
                      question.question_type == "TEXT"
                        ? _c("div", { staticClass: "col-sm-12 padd-10" }, [
                            _c("div", { staticClass: "stats-cls" }, [
                              _c(
                                "h3",
                                {
                                  staticStyle: {
                                    "text-transform": "capitalize"
                                  }
                                },
                                [
                                  _c("strong", [_vm._v("Q: ")]),
                                  _vm._v(_vm._s(question.question))
                                ]
                              ),
                              _c("table", { staticClass: "table" }, [
                                _vm._m(4, true),
                                _c(
                                  "tbody",
                                  _vm._l(question.surveyAnswers, function(
                                    userAnswer,
                                    index
                                  ) {
                                    return _c("tr", { key: index }, [
                                      _c("th", { attrs: { scope: "row" } }, [
                                        _vm._v(_vm._s(index + 1))
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(userAnswer.text_answer))
                                      ]),
                                      _c("td", [
                                        _vm._v(_vm._s(userAnswer.totalResponse))
                                      ])
                                    ])
                                  }),
                                  0
                                )
                              ])
                            ])
                          ])
                        : _vm._e()
                    ])
                  })
                ],
                2
              )
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "li",
      { staticClass: "active", attrs: { role: "presentation" } },
      [
        _c(
          "a",
          {
            attrs: {
              href: "#pickl",
              "aria-controls": "pickl",
              role: "tab",
              "data-toggle": "tab"
            }
          },
          [_vm._v("\n        Pickl Insight Stats\n      ")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _c("span", { staticStyle: { "text-transform": "capitalize" } }, [
        _c("strong", [_vm._v("Textual Question Answers ")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("#")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Answer")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Response Count")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _c("span", { staticStyle: { "text-transform": "capitalize" } }, [
        _c("strong", [_vm._v("Textual Question Answers ")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("#")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Answer")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Response Count")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }